var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('h2', [_vm._v("Legacy Sales Reporting")]), _c('b-row', [_c('b-col', [_c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/index_full_report.php"
    }
  }, [_vm._v("Full Report")]), _vm._v(" "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/national-overview"
    }
  }, [_vm._v("National Overview - Distribution")]), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/totalsalesperstate.php"
    }
  }, [_vm._v("Sales by Products")]), _vm._v(" "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/products-overview"
    }
  }, [_vm._v("Products Overview - Distribution")]), _vm._v(" "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/product-details"
    }
  }, [_vm._v("Products Details - Distribution")]), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/allaccountspersales.php"
    }
  }, [_vm._v("Sales by Accounts")]), _vm._v(" "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/account-chain-performance"
    }
  }, [_vm._v("Account Chain Performance")]), _vm._v(" "), _c('br'), _vm._v(" Chart 5 shows top accounts. Please make sure to double check filter panel. "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/salesbystate.php"
    }
  }, [_vm._v("Sales by State")]), _vm._v(" "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/national-overview"
    }
  }, [_vm._v("National Overview - Distribution")]), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/statesaccounts.php"
    }
  }, [_vm._v("Accounts by State")]), _vm._v(" "), _c('br'), _vm._v(" Needs equivalent "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/productsaccounts.php"
    }
  }, [_vm._v("Accounts by Product")]), _vm._v(" "), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/salesbystate.php"
    }
  }, [_vm._v("Sales by State")]), _vm._v(" "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/products-overview"
    }
  }, [_vm._v("National Overview - Distribution")]), _c('br'), _vm._v(" Chart 3 provides the information of accounts and invoices by product. Please click on tabular view to get data of this chart. "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/salesbymonth.php"
    }
  }, [_vm._v("Sales by Month")]), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/custom-sales-reports"
    }
  }, [_vm._v("Custom Sales Reports")]), _vm._v(" "), _c('br'), _vm._v(" Example Filter: United States - Sales by month"), _c('br'), _vm._v(" Build Custom Report: "), _c('br'), _vm._v(" A: Select Territory / State "), _c('br'), _vm._v(" B: Select Period "), _c('br'), _vm._v(" B: Dimension: Category "), _c('br'), _vm._v(" C: Measures: Spent, Bottles "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/byuser.php"
    }
  }, [_vm._v("Sales by User")]), _vm._v(" "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/national-overview"
    }
  }, [_vm._v("National Overview - Distribution")]), _vm._v(" Improved by territories "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/bycategory.php"
    }
  }, [_vm._v("Sales by Category")]), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/market-overview"
    }
  }, [_vm._v("Market Overview")]), _c('br'), _vm._v(" Chart 3 (Monthly Sales) shows sales by category. It is not grouped by months. "), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/custom-sales-reports"
    }
  }, [_vm._v("Custom Sales Reports")]), _vm._v(" Example Filter: United States - Sales by month"), _c('br'), _vm._v(" Build Custom Report: "), _c('br'), _vm._v(" A: Select Territory / State "), _c('br'), _vm._v(" B: Select Period "), _c('br'), _vm._v(" B: Dimension: Year, Month "), _c('br'), _vm._v(" C: Measures: Spent, Bottles "), _c('br'), _vm._v(" Category filter improved in various reports as well "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/salesbymonthonoff.php"
    }
  }, [_vm._v("Sales by Month/Category")]), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/custom-sales-reports"
    }
  }, [_vm._v("Custom Sales Reports")]), _c('br'), _vm._v(" Build Custom Report: "), _c('br'), _vm._v(" A: Select Territory / State "), _c('br'), _vm._v(" B: Select Period "), _c('br'), _vm._v(" B: Dimension: Year, Month, Category "), _c('br'), _vm._v(" C: Measures: Spent, Bottles "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/salesbymonthandstate.php"
    }
  }, [_vm._v("Sales by Month/State")]), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/custom-sales-reports"
    }
  }, [_vm._v("Custom Sales Reports")]), _c('br'), _vm._v(" Build Custom Report: "), _c('br'), _vm._v(" A: Select Territory / State "), _c('br'), _vm._v(" B: Select Period "), _c('br'), _vm._v(" B: Dimension: Year, Month, State "), _c('br'), _vm._v(" C: Measures: Spent, Bottles "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/accounts3months.php"
    }
  }, [_vm._v("Accounts not ordered >3 months")]), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/expected-next-orders"
    }
  }, [_vm._v("Exepected next orders")]), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/market-overview"
    }
  }, [_vm._v("Market Overview (overdue accounts)")]), _c('br'), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/newaccounts.php"
    }
  }, [_vm._v("New Accounts")]), _c('br'), _vm._v(" Partially in Market overview ... but needs improvement. Possible to use custom report. "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/binnysaccountspersales.php"
    }
  }, [_vm._v("Binny's Sales by Store")]), _vm._v(" | "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/binnystotalsalesperstate.php"
    }
  }, [_vm._v("Binny's Sales by Product")]), _vm._v(" | "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/groupaccountspersales.php"
    }
  }, [_vm._v("Group Sales by Store")]), _vm._v(" | "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/groupproductbyaccount.php"
    }
  }, [_vm._v("Group Sales by Product")]), _vm._v(" | "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/groupproductbystate.php"
    }
  }, [_vm._v("Group Sales by State")]), _c('br'), _vm._v(" Replaced by: "), _c('a', {
    attrs: {
      "href": "#/distribution/account-chain-performance"
    }
  }, [_vm._v("Account Chain Performance")]), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/accounts3months_contacts.php"
    }
  }, [_vm._v("Accounts not ordered >3 months + contacts")]), _vm._v(" | "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/salesreport/salesandcontacts.php"
    }
  }, [_vm._v("Sales and Contacts")]), _c('br'), _vm._v(" No replacement "), _c('br'), _c('br')])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }